import { template as template_98dfe17c36604342b2f94f8af123882c } from "@ember/template-compiler";
const FKText = template_98dfe17c36604342b2f94f8af123882c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
