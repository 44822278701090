import { template as template_d2a459e56bf74d3681b38f9630d4ea92 } from "@ember/template-compiler";
const FKLabel = template_d2a459e56bf74d3681b38f9630d4ea92(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
