import { template as template_b4911b7b7c864bdba7ec8a57ac8386f4 } from "@ember/template-compiler";
const WelcomeHeader = template_b4911b7b7c864bdba7ec8a57ac8386f4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
